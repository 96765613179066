/* eslint-disable no-param-reassign */
Drupal.behaviors.gnavBlockV1 = (function ($) {
  var behavior = {
    attached: false,
    attach: function (context) {
      var self = this;
      var $template = $();

      if (this.attached) {
        return;
      }
      this.attached = true;
      self.bps = Unison.fetch.all();
      self.bp = Unison.fetch.now();
      self.isMobile = parseInt(self.bp.width, 10) < parseInt(self.bps['usn-medium-max'], 10);
      self.position = $(window).scrollTop();
      self.isGnavHovered = false;
      $template = $('.js-gnav-block-v1', context);

      $template.each(function () {
        var $module = $(this);

        self.getDom($module);
        self.setEvents($module, self);

        if (self.isMobile) {
          $module.nodes.$gnavMainNavigationContainer.attr('aria-expanded', 'false');
        }

        $(window)
          .off('scroll.gnavBlock')
          .on(
            'scroll.gnavBlock',
            _.throttle(() => self.handleGnavOnScroll($module), 300)
          );
      });
    },
    closeAccordions: function ($context) {
      $('.js-gnav-submenu-link__input-label', $context).prop('checked', false);
    },
    handleGnavOnScroll: function ($module) {
      const self = this;
      const currentScrollPosition = $(window).scrollTop();
      const isScrollingDown = currentScrollPosition > self.position;
      const isOverlayActive =
        $module.nodes.$gnavMobileHamburgerTrigger.is(':checked') ||
        self.isGnavHovered ||
        $module.nodes.$body.hasClass('active-gnav') ||
        $module.nodes.$body.hasClass('search-overlay-displayed');

      if (isOverlayActive) {
        return;
      }

      self.handleGnavBlockTransitions({
        currentScrollPosition,
        isScrollingDown,
        $module
      });

      self.position = currentScrollPosition;
    },
    handleGnavBlockTransitions: function ({ currentScrollPosition, isScrollingDown, $module }) {
      const shouldStick = currentScrollPosition > $module.nodes.gnavTemplateHeight;
      const shouldHide = isScrollingDown && shouldStick;

      $module.nodes.$html.toggleClass('gnav-block-hidden', shouldHide);
      $module.nodes.$html.toggleClass('gnav-block-sticky-enabled', shouldStick);
    },
    getDom: function ($module) {
      $module.nodes = {};
      $module.nodes.$html = $('html');
      $module.nodes.$body = $('body');
      $module.nodes.$gnavHamburger = $('.js-gnav-block-courtesy-hamburger-link', $module);
      $module.nodes.$gnavMainNavigationContainer = $('.js-gnav-block-main-navigation', $module);
      $module.nodes.$gnavInputMainLinks = $module.nodes.$gnavMainNavigationContainer.find('.js-gnav-block-input-navigation');
      $module.nodes.$gnavLinkBackMainNavMobile = $(
        '.js-gnav-block-main-navigation-link-back',
        $module
      );
      $module.nodes.$gnavLinkMainNavTrigger = $(
        '.js-gnav-block-main-navigation-link',
        $module
      );
      $module.nodes.$gnavLinkMainNavTriggerPc = $(
        '.js-gnav-block-navigation-item[aria-haspopup="true"]',
        $module
      );
      $module.nodes.$gnavCloseNavMobile = $('.js-gnav-block-courtesy-close-link', $module);
      $module.nodes.$gnavMobileHamburgerTrigger = $(
        '#gnav_mobile_input_hamburger_trigger',
        $module
      );
      $module.nodes.gnavTemplateHeight = $module.outerHeight(true);
    },
    setEvents: function ($module, self) {
      Unison.on('change', function (bp) {
        var ariaExpandedFlag;

        self.isMobile = parseInt(bp.width, 10) < parseInt(self.bps['usn-medium-max'], 10);
        ariaExpandedFlag = self.isMobile ? false : true;

        $module.nodes.$gnavMainNavigationContainer.attr('aria-expanded', ariaExpandedFlag);
        if (!self.isMobile) {
          $module.nodes.$gnavInputMainLinks.prop('checked', false);
          $module.nodes.$gnavMobileHamburgerTrigger.prop('checked', false);
        }
      });

      $module.nodes.$gnavHamburger.once().on('click', function () {
        if (self.isMobile) {
          $module.nodes.$gnavMainNavigationContainer.attr('aria-expanded', 'true');
        }
      });

      $module.nodes.$gnavCloseNavMobile.once().on('click', function () {
        var $activeNavigationParent = $();

        if (self.isMobile) {
          $activeNavigationParent = $('.js-gnav-block-main-navigation-layout[aria-expanded="true"]').parent();
          $module.nodes.$gnavInputMainLinks.prop('checked', false);
          $module.nodes.$gnavMainNavigationContainer.attr('aria-expanded', 'false');

          self.collapseGnavContent($activeNavigationParent);
          self.closeAccordions($activeNavigationParent);
        }
      });

      $module.nodes.$gnavLinkMainNavTrigger.once().on('click touchstart', function () {
        var $navigationItemParent = $(this).closest('.js-gnav-block-navigation-item');
        var $currentInputMainNav = $navigationItemParent.find('.js-gnav-block-input-navigation');
        var isCurrentInputChecked = false;

        if (self.isMobile) {
          self.expandGnavContent($navigationItemParent);
        } else if (!self.isMobile && $currentInputMainNav.length > 0) {
          isCurrentInputChecked = $currentInputMainNav.is(':checked');
          $module.nodes.$gnavInputMainLinks.removeAttr('checked');
          if (!isCurrentInputChecked) {
            $currentInputMainNav.attr('checked', 'checked');
          }
        }
      });

      $module.nodes.$gnavLinkBackMainNavMobile.once().on('click touchstart', function () {
        var $navigationItemParent = $();

        if (self.isMobile) {
          $navigationItemParent = $(this).closest('.js-gnav-block-navigation-item');

          self.collapseGnavContent($navigationItemParent);
          self.closeAccordions($navigationItemParent);
        }
      });

      $module.nodes.$gnavLinkMainNavTriggerPc.hover(
        function () {
          self.expandGnavContent($(this));
          self.isGnavHovered = true;
        },
        function () {
          self.collapseGnavContent($(this));
          self.isGnavHovered = false;
        }
      );
    },
    expandGnavContent: function ($that) {
      var $gnavMainNavLayout = $('.js-gnav-block-main-navigation-layout', $that);

      if ($gnavMainNavLayout.length > 0) {
        $gnavMainNavLayout.attr('aria-expanded', 'true');
      }
    },
    collapseGnavContent: function ($that) {
      var $gnavMainNavLayout = $('.js-gnav-block-main-navigation-layout', $that);

      if ($gnavMainNavLayout.length > 0) {
        $gnavMainNavLayout.attr('aria-expanded', 'false');
      }
    }
  };

  return behavior;
})(jQuery);
